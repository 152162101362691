<script>
export default {
  name: 'ModalForbiddenResource',
  methods: {
    close () {
      this.$router.push({ name: this.selectedWorkspace.type + '.home.user' })
    }
  }
}
</script>
<template>
  <v-dialog :value="true" width="600px">
    <v-card class="modal-forbidden-resource--container">
      <div class="modal-forbidden-resource--header__wrapper">
        <v-btn icon @click="close" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="modal-forbidden-resource--body">
        <div class="modal-forbidden-resource--list__wrapper">
          <v-img src="/assets/images/forbidden.svg" width="200" height="200" />
        </div>
        <div class="modal-forbidden-resource--footer">
          <h5>{{ $t('modal.forbidden.resource:title') }}</h5>
          <p class="subtitle--1">{{ $t('modal.forbidden.resource:description') }}</p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-forbidden-resource--container {
  padding: 17px 20px;
  text-align: left;

  .modal-forbidden-resource--header__wrapper {
    height: 36px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .modal-forbidden-resource--body {
    .modal-forbidden-resource--list__wrapper {
      margin: 0 auto;
      text-align: center;
      width: 200px;
    }
    .modal-forbidden-resource--footer {
      margin-top: 24px;
      text-align: center;
      .subtitle--1 {
        color: $neutral-medium
      }
    }
  }
}
</style>
